import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./members.css";

import Profile1 from '../images/members/profile1.jpg'
import Profile2 from '../images/members/profile2.jpg'
import Profile3 from '../images/members/profile3.jpg'
import Profile4 from '../images/members/profile4.jpg'
import Catch1 from '../images/members/catch1.png'
import Catch2 from '../images/members/catch2.png'
import Catch3 from '../images/members/catch3.png'
import Catch4 from '../images/members/catch4.png'
import Elem1 from '../images/service/elem_1.png'

const MembersPage = () => (
  <Layout>
    <SEO title="MEMBERS" />
    <div className="members">
      <div className="title-block">
        <h2>-lecture profiles-</h2>
        <h1>MEMBER</h1>
        <div>
          <p>
            おなまえメソッド
            <span className="registerd-sign">&#174;</span>
            で
          </p>
          <p>活躍しているメンバーを</p>
          <p>ご紹介します！</p>
        </div>
      </div>
      <div className="profile">
        <div className="profile-block">
          <div className="photo">
            <div className="image left">
              <p className="back"></p>
              <img alt="KUMIKO YAMAMOTO" src={Profile1} className="scale-to-fit" />
            </div>
            <div className="links">
              <a href="https://www.instagram.com/community.kumiko/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
              <a href="https://ameblo.jp/kurasakimai/" target="_blank" rel="noopener noreferrer">AMEBA BLOG</a>
            </div>
            <div className="btn-holder">
              <a href="mailto:kumiko.yamamoto935@gmail.com?subject=個人鑑定の申込み">この講師のお名前個人鑑定を申し込む</a>
            </div>
          </div>
          <div className="text">
            <div className="catch">
              <img alt="自分を見つめる時間が大切！" src={Catch1} className="scale-to-fit" />
            </div>
            <div className="name">
              <p className="en">KUMIKO YAMAMOTO</p>
              <p className="ja">山本 久美子</p>
            </div>
            <div className="position">
              <div className="main">
                 <p>
                   おなまえメソッド
                   <span className="registerd-sign">&#174;</span>
                 </p>
                 <p>公認講師</p>
              </div>
              <div className="sub">
                <p>ママメンタル専門家</p> 
                <p>地方ママの起業育成</p>
              </div>
            </div>
            <ul className="read">
              <li>
子育て中のシングルママ<br />
頑張ってる女性たちへ届けたいメソッド<br />
女性こそ自分を見つめる時間が大切と自身の経験から心底感じました。 
              </li>
              <li>
自分と向き合いながら守りたい家族たちも笑顔にしたい。<br />
そう強く願ったときに出会ったこのメソッド。<br />
心身折れかかったなんでもない主婦だった私が頑張りたいと願う女性たちをサポートする側へとなりました。
              </li>
              <li>
願いは叶う。自分は最高に楽しくって最強の相棒。<br />
大丈夫。すべてはうまくいく。と信じれる自分自身に必ず変わります。
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-block">
          <div className="photo">
            <div className="image right">
              <p className="back"></p>
              <img alt=" " src={Profile2} className="scale-to-fit" />
            </div>
            <div className="links">
              <a href="https://www.instagram.com/yuzuki.104/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
              <a href="https://ameblo.jp/iyashipuchi/" target="_blank" rel="noopener noreferrer">AMEBA BLOG</a>
            </div>
            <div className="btn-holder">
              <a href="mailto:kaiun.55.yuiki@gmail.com?subject=個人鑑定の申込み">この講師のお名前個人鑑定を申し込む</a>
            </div>
          </div>
          <div className="text">
            <div className="catch">
              <img alt="もう幸せ開運を手にしてる" src={Catch2} className="scale-to-fit" />
            </div>
            <div className="name">
              <p className="en">YUZUKI KANO</p>
              <p className="ja">叶 結月</p>
            </div>
            <div className="position">
              <p className="main">
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                公認講師
              </p>
              <div className="sub">
                <p>
                  名前開運招福士 おなまえ
                  <span className="registerd-sign">&#174;</span>
                </p>
                <p>曼荼羅アート講師</p>
              </div>
            </div>
            <ul className="read">
              <li>
「時には泣いてもよかとよ！しっかり前見ていきんしゃい！」
              </li>
              <li>
あなたの名前を見れば、本当のあなたがハッキリとわかりますよ！
              </li>
              <li>
私自身も、開運を追いかけて必死でした。<br />
だけど、運は必死になるものじゃないっていおなまえメソッド
<span className="registerd-sign">&#174;</span>
で知ることが出来ました。
              </li>
              <li>
だからあなたも知ってほしい！<br />
あなたはもう「幸せ開運」を手にしてるって。
              </li>
              <li>
「名前」の使い方きっちり教えちゃる。自分が持つ最高の運を活用して自分の力で自分自身を開花させる方法を。
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-block">
          <div className="photo">
            <div className="image left">
              <p className="back"></p>
              <img alt="EMIRI KATASE" src={Profile3} className="scale-to-fit" />
            </div>
            <div className="links">
              <a href="https://www.instagram.com/emiri_katase.megamituma/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
              <a href="https://ameblo.jp/emirimind/" target="_blank" rel="noopener noreferrer">AMEBA BLOG</a>
            </div>
            <div className="btn-holder">
              <a href="mailto:emiri.loveonamae@gmail.com?subject=個人鑑定の申込み">この講師のお名前個人鑑定を申し込む</a>
            </div>
          </div>
          <div className="text">
            <div className="catch">
              <img alt="何もないすべてがゼロ！" src={Catch3} className="scale-to-fit" />
            </div>
            <div className="name">
              <p className="en">EMIRI KATASE</p>
              <p className="ja">片瀬 絵未里</p>
            </div>
            <div className="position">
              <p className="main">
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                公認講師
              </p>
              <div className="sub">
                <p>パートナーシップ専門家</p>
                <p>女神妻コーチング</p>
              </div>
            </div>
            <ul className="read">
              <li>
何もない。全てがゼロ！と宣言できる方こそおなまえメソッド
<span className="registerd-sign">&#174;</span>
をお勧めします。
              </li>
              <li>
何の取り柄もないと思い込んでるその思考をひっくり返します！
              </li>
              <li>
人生を、家族と大切な仲間と一緒に幸せを感じて自分を満喫することは誰でもできる。<br />
「何もないゼロだった私がなれた」のだから誰でもある「名前」の計り知れないパワーと活用法を余すことなく公認講師としお伝えします！
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-block">
          <div className="photo">
            <div className="image right">
              <p className="back"></p>
              <img alt="武内愛" src={Profile4} className="scale-to-fit" />
            </div>
            <div className="links">
              <a href="https://instagram.com/insta_kamifude.ai/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
              <a href="https://qoosyogakuka.amebaownd.com/" target="_blank" rel="noopener noreferrer">AMEBA BLOG</a>
            </div>
            <div className="btn-holder">
              <a href="mailto:aimantenkamifude@gmail.com?subject=個人鑑定の申込み">この講師のお名前個人鑑定を申し込む</a>
            </div>
          </div>
          <div className="text">
            <div className="catch">
              <img alt="かろやかに、しなやかに" src={Catch4} className="scale-to-fit" />
            </div>
            <div className="name">
              <p className="en">AI TEKEUCHI</p>
              <p className="ja">武内 愛</p>
            </div>
            <div className="position">
              <p className="main">
                おなまえメソッド
                <span className="registerd-sign">&#174;</span>
                公認講師
              </p>
              <div className="sub">
                <p>
                  地域創生デザイナー おなまえ
                  <span className="registerd-sign">&#174;</span>
                </p>
                <p>神筆文字講師</p>
              </div>
            </div>
            <ul className="read">
              <li>
筆文字で日本の古代文字<br />
ヲシテ文字の作品を制作している上で名前の重要性を感じていました。  
              </li>
              <li>
おなまえメソッド
<span className="registerd-sign">&#174;</span>
を学び<br />
さらに名前からいただけるヒントやメッセージを受け取ることができます。
              </li>
              <li>
軽やかに<br />
しなやかに<br />
自分の人生を生きる生き方を伝えます。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="service">
        <div className="title-box">
          <p><img alt="service" src={Elem1} className="scale-to-fit" /></p>
          <h1>SERVICE</h1>
          <p><img alt="service" src={Elem1} className="scale-to-fit" /></p>
        </div>
        <p className="sub-title">
          おなまえメソッド
          <span className="registerd-sign">&#174;</span>
          は、3つのサービスをご用意しています！
        </p>
        <div className="btn-holder">
          <Link to='/service'>サービス詳細はこちら</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default MembersPage
